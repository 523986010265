import React from 'react'
import { Layout } from '../components/layout'
import { BioCardImage, LayoutBackground } from '../components/shared'
import { BioHeroImage } from '../components/shared/molecules/BioHeroImage'

export const SinglePage = ({
  title,
  subtitle,
  heroImage,
  heroImageMobile,
  heroTitle,
  children,
  reducedFooter,
  lunchNewsletter,
  usedWithNewBioDivider,
  translations,
  fullWidthHeader,
  icon,
  titleIcon,
  hasSocialSharing
}) => {
  const subtitleComponent = subtitle && (
    <h2 className="text-center bioSubtitle">{subtitle}</h2>
  )
  const layoutBackgroundHeroComponent = heroImage && (
    <BioHeroImage
      image={heroImage}
      imageMobile={heroImageMobile}
      heroTitle={heroTitle}
      lunchNewsletter={lunchNewsletter}
      usedWithNewBioDivider={usedWithNewBioDivider}
      fullWidthHeader={fullWidthHeader}
      title={title}
      icon={icon}
      titleIcon={titleIcon}
    />
  )
  const layoutBackgroundHeaderComponent = !heroImage && title && (
    <div className="mb-14 md:mb-24">
      <h1 className="break-words" data-testid="single-page-title">
        {title}
      </h1>
      {subtitleComponent}
    </div>
  )
  return (
    <>
      <Layout
        standardPaddingTopFromNavbarToMain
        reducedFooter={reducedFooter}
        isSinglePage={true}
        translations={translations}>
        <div className="relative">
          <LayoutBackground
            heroComponent={layoutBackgroundHeroComponent}
            hasSocialSharing={hasSocialSharing}
            headerComponent={layoutBackgroundHeaderComponent}>
            {children}
          </LayoutBackground>
        </div>
      </Layout>
    </>
  )
}
