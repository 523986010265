import React from 'react'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import classNames from 'classnames'
import * as styles from '../../../css/molecules/bioHeroImage.module.scss'
import { BioHeroBoxLogo } from '../backgrounds/BioHeroBoxLogo'
import useIsMobileDetect from '../../../utils/useIsMobileDetect'

export const BioHeroImage = ({
  image,
  imageMobile,
  icon,
  titleIcon,
  title,
  heroTitle,
  lunchNewsletter,
  usedWithNewBioDivider = false,
  fullWidthHeader = false
}) => {
  const oldStyle = {
    position: 'absolute',
    left: '0'
  }
  const mobile = useIsMobileDetect(600)
  return (
    <div className={classNames(!fullWidthHeader && 'container', 'relative')}>
      <BgImage
        data-cy="heroImage"
        Tag="section"
        image={
          mobile && imageMobile
            ? getImage(imageMobile?.asset)
            : getImage(image?.image?.asset)
        }
        alt={image?.image?.alt}
        className={styles.bioHeroImage}
        style={oldStyle}>
        {(titleIcon || icon) && (
          <BioHeroBoxLogo>
            {titleIcon || icon ? (
              <div data-cy="bioHeroBoxLogo">
                <img
                  className={styles.__bioBoxLogoImage}
                  src={titleIcon?.image?.asset?.url || icon?.image?.asset?.url}
                />
              </div>
            ) : null}
          </BioHeroBoxLogo>
        )}
        {heroTitle && (
          <h1
            className={classNames(
              'px-4 w-full md:w-4/5 lg:w-3/4 xl:w-2/3 headline-text-shadow text-center text-white break-words',
              lunchNewsletter && 'lg:w-3/5 xl:w-3/5'
            )}>
            {title}
          </h1>
        )}
      </BgImage>
    </div>
  )
}
